<template>
  <div class="guide">
    <img src="https://qiniu.zhiyunys.com/img/guide1.png" />
    <img src="https://qiniu.zhiyunys.com/img/guide2.png" />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
  .guide {
    img {
      width: 100%;
      margin-bottom: 10px;
    }
  }
</style>