<template>
  <div class="help">
    <div class="content">
      <h1>售后专属客服</h1>
      <h2>您的问题我们都将为您及时解决</h2>
      <div class="card">
        <h3>如需帮助，请添加专属售后客服微信</h3>
        <img src="https://qiniu.zhiyunys.com/img/weixincode.jpg" />
        <p>使用微信扫一扫</p>
        <p class="wechat">
          <label>微信号：</label>
          <span id="account">gxjdwh888</span>
          <a class="copy" @click="copy">复制</a>
        </p>
      </div>
      <img class="step" src="https://qiniu.zhiyunys.com/img/wg_step.png" />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    copy() {
      const range = document.createRange();
      range.selectNode(document.getElementById('account'));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
    }
  }
}
</script>

<style lang="less" scoped>
.help {
  background: #f5f5f5 url('https://qiniu.zhiyunys.com/img/wg_bg.png') no-repeat;
  background-size: 100% 310px;
  min-height: 100vh;
  .content {
    padding: 60px 20px;
    h1 {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 15px;
      color: #fff;
    }
    h2 {
      font-size: 18px;
      font-weight: normal;
      color: #fff;
    }
    .card {
      padding: 30px 20px;
      background: #fff;
      border-radius: 15px;
      margin-top: 40px;
      text-align: center;
      h3 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }
      img {
        width: 60%;
      }
      p {
        font-size: 12px;
        color: #999;
        &.wechat {
          margin-top: 20px;
          color: #333;
          font-size: 14px;
          #account {
            color: #de4c4c;
            font-weight: bold;
          }
          .copy {
            display: inline-block;
            padding: 4px 6px;
            font-size: 12px;
            background: #ce8752;
            color: #fff;
            border-radius: 2px;
            margin-left: 10px;
          }
        }
      }
    }
    .step {
      margin-top: 30px;
      width: 100%;
    }
  }
}
</style>