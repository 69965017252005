<template>
  <div class="paycode">
    <div class="wx_header">
      <div class="wx_logo">
        <img
          title="微信支付"
          alt="微信支付标志"
          src="https://qiniu.zhiyunys.com/img/wxlogo_pay.png"
        />
      </div>
    </div>
    <div class="weixin">
      <div class="weixin2">
        <b class="wx_box_corner left pngFix"></b
        ><b class="wx_box_corner right pngFix"></b>
        <div class="wx_box pngFix">
          <div class="wx_box_area">
            <div class="pay_box qr_default">
              <div class="area_bd" id="showGuide">
                <span id="qr_box" class="wx_img_wrapper"
                  ><div id="qrcode" style="display: inline-block"></div>
                  <img
                    src="https://qiniu.zhiyunys.com/img/wxwebpay_guide.png"
                    alt=""
                    class="guide pngFix"
                    :style="style"
                /></span>
                <div class="msg_default_box">
                  <i class="icon_wx pngFix"></i>
                  <p>
                    请使用微信
                    <br />扫一扫完成支付
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="wx_hd">
            <div class="wx_hd_img icon_wx"></div>
          </div>
          <div class="wx_money">
            <span>￥</span>
            {{totalPrice}}
          </div>
          <div class="wx_pay">
            <p>
              <span class="wx_left">支付订单号</span
              ><span class="wx_right">{{info.odd}}</span>
            </p>
            <p>
              <span class="wx_left">支付项目</span
              ><span class="wx_right">{{info.orderType}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import api from '@/apis/order';

export default {
  data() {
    return {
      price: localStorage.getItem('price'),
      style: {},
      timer: null,
      info: {},
      code_url:"",
      redPacket:0
    };
  },
  computed: {
    totalPrice() {
      if (this.info.price) {
        const result = this.info.price - this.redPacket;
        return result.toFixed(2);
      } else {
        return '0.00'; // Always return the price as a string with two decimal places
      }
    }
  },
  mounted() {
    let newObj = JSON.parse(this.$route.query.info);
    if (localStorage.getItem('redPacket' + newObj.odd)) {
        this.redPacket = localStorage.getItem('redPacket' + newObj.odd)
        console.log('hhhh',this.redPacket);
    }
    this.code_url = newObj.code_url;
    // this.info = JSON.parse(this.$route.query.info);
    this.qrcode();
    this.showGuide();
    this.timer = setInterval(() => {
      this.searchStatus();
    }, 2000);
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    async searchStatus() {
      await api.getOrder({
        odd: localStorage.getItem("odd"),
        orderType: '周公解梦'
      }).then(res => {
        this.info = res[0] || {};
        if (res[0].status !== 0) {
          clearInterval(this.timer);
          this.timer = null;
          this.$router.push({
            path: '/success',
            query: {
              status: 1
            }
          });
        }
      });
    },
    qrcode() {
      new QRCode("qrcode", {
        width: 280, // 设置宽度，单位像素
        height: 280, // 设置高度，单位像素
        text: this.code_url, // 设置二维码内容或跳转地址
      });
    },
    showGuide() {
      const _this = this;
      document.getElementById('showGuide').addEventListener('mouseenter', () => {
        _this.style = {
          left: '280px',
          opacity: 1,
        };
      });
      document.getElementById('showGuide').addEventListener('mouseleave', () => {
        _this.style = {
          left: '0px',
          opacity: 0,
        };
      });
    },
  },
};
</script>

<style lang="less" scoped>
.paycode {
  width: 1000px;
  position: absolute;
  top: 0;
  left: -280px;
  font-size: 0.23rem;
  .wx_header {
    background: url(https://qiniu.zhiyunys.com/img/wx_icon.png);
    background-position: 0 -235px;
    height: 66px;
    margin-bottom: 7px;
    .wx_logo {
      text-align: center;
      padding-top: 16px;
    }
  }
  .weixin {
    .weixin2 {
      margin: 0 auto 25px;
      width: 920px;
      background: url(https://qiniu.zhiyunys.com/img/wxpay_bg.png) repeat-x #fff;
      background-position: -5px -60px;
      -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 35%);
      box-shadow: 0 1px 1px rgb(0 0 0 / 35%);
      position: relative;
      .wx_box_corner {
        background: url(https://qiniu.zhiyunys.com/img/wxpay_bg_left.png) no-repeat;
        height: 30px;
        position: absolute;
        top: 0;
        width: 6px;
      }
      .wx_box_corner.left {
        background-position: 0 0;
        left: -5px;
      }
      .wx_box_corner.right {
        background-position: 0 -30px;
        right: -5px;
      }
      .wx_box {
        background: url(https://qiniu.zhiyunys.com/img/wepay_border.png) repeat-x 0 100%;
        bottom: -10px;
        overflow: hidden;
        padding: 60px 170px 100px;
        position: relative;
        .icon_wx {
          background: url(https://qiniu.zhiyunys.com/img/wx_icon.png);
          display: inline-block;
          height: 60px;
          vertical-align: middle;
          width: 60px;
          background-position: 0 -60px;
        }
        .wx_box_area {
          text-align: center;
          .wx_img_wrapper {
            display: block;
            width: 280px;
            height: 306px;
            margin: 0 auto;
            position: relative;
            @keyframes toLeft {
              from {
                opacity: 0;
                left: 0;
              }
              to {
                opacity: 1;
                left: 280px;
              }
            }
            .guide {
              width: 282px;
              height: 408px;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              transition: all ease-out .8s;
            }
          }
          .msg_default_box {
            background-color: #445f85;
            border: 1px solid #2b4d69;
            border-radius: 3px;
            color: #fff;
            letter-spacing: 6px;
            padding: 12px 0;
            text-align: center;
            display: inline-block;
            width: 288px;
            i {
              margin-left: -16px;
            }
            p {
              display: inline-block;
              line-height: 1.5;
              font-size: 16px;
              letter-spacing: normal;
              text-align: left;
              vertical-align: middle;
            }
          }
        }
        .wx_hd {
          border-bottom: 3px solid #e0e3eb;
          width: 100%;
          position: relative;
          height: 70px;
          .wx_hd_img {
            width: 60px;
            height: 60px;
            display: block;
            background-color: #fff;
            display: inline-block;
            padding-left: 12px;
            padding-right: 14px;
            position: relative;
            top: 34px;
            left: 250px;
            background-position: 11px -130px;
          }
        }
        .wx_money {
          text-align: center;
          border-bottom: 1px solid #d0d8e4;
          color: #585858;
          font-size: 60px;
          font-weight: 400;
          line-height: 68px;
          padding-bottom: 20px;
          padding-top: 50px;
          width: 100%;
        }
        .wx_pay {
          padding: 30px 0 60px;
          p {
            width: 100%;
            display: block;
            overflow: hidden;
            height: 20px;
            line-height: 20px;
            color: #8e8e8e;
            font-size: 14px;
            .wx_left {
              float: left;
              text-align: left;
            }
            .wx_right {
              float: right;
              text-align: right;
              color: #303030;
            }
          }
        }
      }
    }
  }
}
</style>