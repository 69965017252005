import api from '@/utils/request';

const order = {
  getList: (param) => api.post('/Oneiromancy/orderList', param), // 获取订单列表
  getOrder: (param) => api.post('/order/getOrder', param), // 根据订单号/手机号查询订单
  createOrder: (param) => api.post('/Oneiromancy/createOrder', param), // 创建订单
  deleteOrder: (param) => api.post('/Oneiromancy/delRecord', param), // 删除订单
  alipay: (param) => api.post('/Oneiromancy/alipay', param), // 支付宝支付
  wxpay: (param) => api.post('/Oneiromancy/wechatpay', param), // 微信支付
  alipay1: (param) => api.post('/Oneiromancy/alipay1', param), // 支付宝支付
  wxpay1: (param) => api.post('/Oneiromancy/wechatPay1', param), // 微信支付
  bindPhone: (param) => api.post('/Oneiromancy/bindingPhoneNumber', param), // 绑定手机号
  getWxOrder: (param) => api.post('/Oneiromancy/wechatOrderFind', param), // 查询微信订单
  getAliOrder: (param) => api.post('/Oneiromancy/aliOrderFind', param), // 查询支付宝订单
  dreamResult:(param)=>api.post('Oneiromancy/result',param), //梦境结果
  getContent: (param) => api.post('/Oneiromancy/result', param), // 获取详情页
};

export default order;