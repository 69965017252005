import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
Vue.config.productionTip = false

import less from 'less'
Vue.use(less)

import vant from 'vant';
import { Lazyload, Toast, DatetimePicker, Popup, RadioGroup, Radio } from 'vant';
import 'vant/lib/index.css';
Vue.use(vant).use(Lazyload).use(Toast).use(DatetimePicker).use(Popup).use(RadioGroup).use(Radio);

import Mint from 'mint-ui';
import 'mint-ui/lib/style.css';
Vue.use(Mint);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import Main from '@/pages/Main'
import Process from '@/pages/Process'
import ResultPayed from '@/pages/ResultPayed'
import PayResult from '@/pages/PayResult'
import Order from '@/pages/Order'
import WxPay from '@/pages/WxPay'
import Guide from '@/pages/Guide'
import Help from '@/pages/Help'
import Success from '@/pages/Success'
import Wrapper from '@/pages/Wrapper'
import Protocol from '@/pages/Protocol'
// import Vconsole from 'vconsole';
// new Vconsole()
import fastClick from 'fastclick';
fastClick.attach(document.body);
fastClick.prototype.needsFocus = function (target) { //判断当前元素是否需要focus
  switch (target.nodeName.toLowerCase()) {
    case 'textarea':
      return true;
    case 'select':
      return !(navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1);
    case 'input':
      switch (target.type) {
        case 'button':
        case 'checkbox':
        case 'file':
        case 'image':
        case 'radio':
        case 'submit':
          return false;
      }
      // No point in attempting to focus disabled inputs
      return !target.disabled && !target.readOnly;
    default:
      return (/\bneedsfocus\b/).test(target.className);
  }
};
fastClick.prototype.focus = function (targetElement) {
  var length;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length);
    /*修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘*/
    targetElement.focus();
  } else {
    targetElement.focus();
  }
};
Date.prototype.Format = function (fmt) { //author: meizz 
  var o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}
function setRootFontSize() {
  var width = document.documentElement.clientWidth || document.body.clientWidth;
  if (width > 460) {
    width = 460;
  }
  const fontSize = (width * 0.1);
  document.getElementsByTagName('html')[0].style['font-size'] = fontSize + 'px';
}
setRootFontSize();
window.addEventListener('resize', function () {
  setRootFontSize();
}, false);
const router = new VueRouter({
  mode: 'history',
  // mode: 'hash',
  base: '/zhougong/',
  routes: [
    { path: '/', redirect: '/main', meta: { isPush: true } },
    { path: '/main', component: Main, meta: { isPush: true } },
    { path: '/process', component: Process, meta: { isPush: true } },
    { path: '/order', component: Order, meta: { isPush: true } },
    { path: '/resultPayed', component: ResultPayed, meta: { isPush: true } },
    { path: '/payResult', component: PayResult, meta: { isPush: true } },
    { path: '/wxPay', component: WxPay, meta: { isPush: true } },
    { path: '/guide', component: Guide, meta: { isPush: true } },
    { path: '/help', component: Help, meta: { isPush: true } },
    { path: '/success', component: Success, meta: { isPush: true } },
    { path: '/wrapper', component: Wrapper, meta: { isPush: true } },
    { path: '/Protocol', component: Protocol, meta: { isPush: true } },
  ]
})
router.beforeEach((to, from, next) => {
  let query = { ...to.query };
  if (!to.query.apkChannel) {
    // query.apkChannel = 'BD_zwds01_ty_A04_lh';
    query.apkChannel = from.query.apkChannel || 'noChannel';
  }
  if (!to.query.isCallBack) {
    query.isCallBack = from.query.isCallBack || 0;
  }
  if (!to.query.bd_vid) { // 百度
    query.bd_vid = from.query.bd_vid;
  }
  if (!to.query.uctrackid) { // 神马
    query.uctrackid = from.query.uctrackid;
  }
  if (!to.query.pageId) { // 志云
    query.pageId = from.query.pageId;
  }
  if (!to.query.tid) { // 志云
    query.tid = from.query.tid;
  }
  if (!to.query.lbid) { // 志云
    query.lbid = from.query.lbid;
  }
  
  if (to.query.apkChannel) {
    next();
  } else {
    next({
      path: to.path === '/' ? '' : to.path,
      query,
    });
  }
})
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
