<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "@/assets/reset.css";

export default {
  name: "App",
  mounted() {
    //通过监听hashchange事件 判断当前页面的路由与实际的路由是否一致
    window.addEventListener(
      "hashchange",
      () => {
        let currPath = window.location.hash.slice(1);
        if (this.$route.fullPath !== currPath) {
          // 不一致就跳转url的路由
          this.$router.push(currPath);
        }
      },
      false
    );
  },
};
</script>

<style lang="less">
#app {
  max-width: 460px;
  margin: auto;
  position: relative;
}
body {
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
.fix-order {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 40%;
}
.fix-order img {
  width: 0.6rem !important;
}
// input, input:focus,
// textarea, textarea:focus,
// select, select:focus {
//   font-size: 16px !important;
// }
body {
  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
</style>
